import React from "react"
import Html from "../../../types/html"

interface Props {
  html?: Html
  className?: string
}

const TextContainer: React.FC<Props> = ({ children, html, className }) => {
  return (
    <div className={`flex flex-col items-center w-100 ${className || ""}`}>
      {children ? (
        <div className="max-w-2xl px-3 md:px-6 text-gray-800">{children}</div>
      ) : (
        <p
          className="max-w-2xl px-3 md:px-6 text-gray-800"
          dangerouslySetInnerHTML={{ __html: html }}
        ></p>
      )}
    </div>
  )
}

export default TextContainer
