import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import TextContainer from "../components/elements/TextContainer"

const ParticipatePage = () => {
  const listClass = "y-2 md:py-3 ml-6 md:ml-8"
  return (
    <React.Fragment>
      <div className="flex justify-center py-8">
        <img
          className="block w-48 h-48 md:w-64 md:h-64"
          src="/images/bears/3.svg"
          alt=""
        />
      </div>

      <TextContainer>
        <h1 className="text-xl md:text-2xl bm-4 md:mb-6 text-center mb-4 font-bold">
          Как стать участником арт-проекта #ДРУГМОЙМИШКА_КРАСНЫЙ ?
        </h1>
        <ol className="list-decimal text-lg leading-relaxed mb-12">
          <li className={listClass}>
            Напиши о своем желании участвовать на емейл{" "}
            <a
              href="mailto:drugmoimishka@yandex.ru"
              className="text-blue-600 underline"
            >
              drugmoimishka@yandex.ru
            </a>
            ; мы сразу вышлем тебе подробное описание и анкету
          </li>
          <li className={listClass}>
            Заполни анкету, и мы отправим тебе почтой РФ нашего героя или
            договоримся о личной встрече
          </li>
          <li className={listClass}>
            Придумай и нарисуй маленькую историю Красного мишки (20х20см),
            приклеив на нее нашего бумажного героя
          </li>
          <li className={listClass}>
            Отправь историю и картину в г. Красногорск, нам.
          </li>
        </ol>
      </TextContainer>
    </React.Fragment>
  )
}

export default ParticipatePage
